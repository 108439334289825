import React from "react";
import {HashLink as Link} from "react-router-hash-link";
import {Button, Header, Dimmer, Loader, Icon, Card, Segment, Divider, Modal} from "semantic-ui-react";
import firebase from "firebase";
import {FIREBASE_CONFIG, randomString} from "./const";
import EditModal from "./EditModal";
import UserSettings from "./UserSettings";

export default class Dashboard extends React.Component {
    constructor(props){
        super(props);
        this.state = {loading: true, data: {}};
        if(firebase.apps.length == 0) firebase.initializeApp(FIREBASE_CONFIG);
        this.db = firebase.firestore();
        firebase.auth().onAuthStateChanged((u) => {
            console.log("auth in");
            if(u){
                this.user = u;
                console.log("Signed in");
                this.fetchDevices();
            }else{
                window.location.replace("/auth?dest=" + encodeURIComponent(window.location));
            }
        });
    }

    setNeedsSync = () => {
        this.setState({loading: true});
        this.db.collection("users").doc(this.user.uid).update({needsSync: true}).then(() => {
            this.fetchDevices();
        }, (e) => {
            console.error(e);
        });
    }

    fetchDevices = () => {
        console.log("fetching devices");
        this.db.collection("users").doc(this.user.uid).get().then((doc) => {
            this.setState({data: doc.data(), loading: false});
        }, (e) => {
            console.error(e);
        });
    };

    render(){
        if(this.state.loading){
            return (
                <Dimmer active>
                    <Loader />
                </Dimmer>
            );
        }

        let devices = Object.keys(this.state.data.devices).map((key) => {
            const n = this.state.data.devices[key];
            return (
                <Card>
                    <Card.Content>
                        <Segment compact basic floated="left" style={{margin: 0, padding: 0, fontSize: "17px"}}>
                            <Card.Header>
                                {n.name}
                            </Card.Header>
                            <Card.Meta style={{marginTop: "7px"}}>
                                {n.type}
                            </Card.Meta>
                        </Segment>
                        <EditModal
                            device={n}
                            onDelete={() => {
                                this.setState({loading: true});
                                this.db.collection("users").doc(this.user.uid).update({
                                    ["devices." + key]: firebase.firestore.FieldValue.delete()
                                }).then(() => {
                                    this.setNeedsSync();
                                }, (e) => {
                                    console.error(e);
                                });
                            }}  
                            onSave={(newData) => {
                                this.setState({loading: true});
                                this.db.collection("users").doc(this.user.uid).update({
                                    ["devices." + key]: newData
                                }).then(() => {
                                    this.setNeedsSync();
                                }, (e) => {
                                    console.error(e);
                                });
                            }}
                        />
                    </Card.Content>
                </Card>
            );
        });

        
        return (
            <div className="fullPagePadded">
                <Header size="huge" icon><Icon name="dashboard" />Dashboard</Header>
                <Divider hidden/>
                <Link to="/docs"><Button circular icon="file alternate outline" labelPosition="left" content="View Documentation" /></Link>
                <Button circular negative icon="sign out" labelPosition="left" content="Sign Out" onClick={() => {
                    firebase.auth().signOut().then(() => {
                        this.setState({loading: true});
                        window.location.replace("/auth?dest=" + encodeURIComponent(window.location));
                    }, (e) => {
                        console.error(e);
                    });
                }} />
                <UserSettings data={this.state.data} onSave={(newData) => {
                    this.setState({loading: true});
                    this.db.collection("users").doc(this.user.uid).set(newData).then(() => {
                        this.fetchDevices();
                    }, (e) => {
                        console.error(e);
                    });
                }}/>
                <Divider />
                <Card.Group style={devices.length == 0 ? {height: "97.5px"} : null}>
                    {
                        this.state.data.needsSync ?
                        <Card key="sync" onClick={() => {
                            this.setState({loading: true});
                            let fn = firebase.functions().httpsCallable("requestSync");
                            fn().then(() => {
                                this.db.collection("users").doc(this.user.uid).update({
                                    needsSync: false
                                }).then(() => {
                                    this.fetchDevices();
                                }, (e) => {
                                    console.error(e);
                                })
                                
                            });
                        }}>
                            <Card.Content>
                                <Segment compact basic floated="left" style={{margin: 0, padding: 0, fontSize: "17px"}}>
                                    <Card.Header>
                                        Sync Devices
                                    </Card.Header>
                                    <Card.Meta style={{marginTop: "7px"}}>
                                        Click to update Google Assistant with the new device settings.
                                    </Card.Meta>
                                </Segment>
                            </Card.Content>
                        </Card>
                        : null
                    }
                    <Card raised key="new" link style={{position: "relative", backgroundColor: "#42f241"}} onClick={() => {
                        this.setState({loading: true});
                        this.db.collection("users").doc(this.user.uid).update({
                            ["devices." + randomString()]: {
                                fulfillment: {
                                    service: "dummy"
                                },
                                type: "Switch",
                                name: "New Device",
                                traits: ["OnOff"],
                                attributes: {}
                            }
                        }).then(() => {
                            this.setNeedsSync();
                        }, (e) => {
                            console.error(e);
                        });
                    }}>
                        <Icon name="plus" size="huge" style={{color: "white", position: "absolute", left: 0, right: 0, top: 0, bottom: 0, margin: "auto"}}/>
                    </Card>
                    {devices}
                </Card.Group>
            </div>
        );
    }
}