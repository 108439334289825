import React from "react";
import {Button, Message, Input, Header, Segment, Form, TextArea} from "semantic-ui-react";
import {HashLink as Link} from "react-router-hash-link";

export class Dummy extends React.Component {
    constructor(props){
        super(props);
        this.state = {data: this.props.data, returnStateError: false, returnState: (this.props.data.return_state == undefined ? "{ }" : JSON.stringify(this.props.data.return_state))};
    }

    render(){
        return (
            <Segment>
                <Link to="/docs#fulfillment-dummy" target="_blank"><Button floated="right" circular icon="help" style={{marginRight: "15px", marginTop: "15px"}} /></Link>
                <Header as="h2">No fulfillment</Header>
                <p>The "dummy" fulfillment makes the device completely virtual; it exists in the cloud, but physically does nothing. This fulfillment is used for testing purposes.</p>
                <Header icon="file code" content="Return State" />
                <p>Since this device has no physical counterpart, therefore also has no state, you can enter a static state to be returned to the Google Assistant in JSON format, described on the <a href="https://developers.google.com/actions/smarthome/traits/" target="_blank">Google Assistant documentation</a>.</p>
                {this.state.returnStateError ? <Message header="JSON data error" content="Please make sure your JSON data is typed correctly. Incorrect data will not be saved." icon="exclamation" error /> : null}
                <Form>
                    <TextArea autoHeight placeholder="Enter the state to be returned, in JSON format" value={this.state.returnState} onChange={(event, props) => {
                        let text = props.value;
                        if(text.length < 2) text = "{}";
                        if(text[0] != "{") text = "{" + text;
                        if(text[text.length - 1] != "}") text += "}";
                        this.setState({returnState: text});
                        try {
                            const parsed = JSON.parse(text);
                            this.setState({returnStateError: false});
                            let newData = this.state.data;
                            newData.return_state = parsed;
                            this.props.onChange(newData);
                        } catch (e) {
                            this.setState({returnStateError: true});
                            return;
                        }

                    }} />
                </Form>
            </Segment>
        );
    }
}

export class Particle extends React.Component {
    constructor(props){
        super(props);
        this.state = {data: this.props.data};
    }

    render(){
        return (
            <Segment>
                <Link to="/docs#fulfillment-particle" target="_blank"><Button floated="right" circular icon="help" style={{marginRight: "15px", marginTop: "15px"}} /></Link>
                <Header icon="hashtag" content="Device ID" />
                <Input placeholder="Device ID" error={this.state.data.device_id == undefined || this.state.data.device_id == ""} value={this.state.data.device_id == undefined ? "" : this.state.data.device_id} onChange={(e, props) => {
                    let newData = this.state.data;
                    newData.device_id = props.value;
                    this.setState({data: newData});
                    this.props.onChange(newData);
                }}/>
            </Segment>
        );
    }
}