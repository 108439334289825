import React from "react";
import {Button, Modal, Accordion, Header, Input, Icon, Message} from "semantic-ui-react";

export default class UserSettings extends React.Component {
    constructor(props){
        super(props);
        this.state = {data: this.props.data, expanded: -1};

        const FULFILLMENT_SERVICES = {
            "particle.io": {
                access_token: ""
            }
        };
        for(let key of Object.keys(FULFILLMENT_SERVICES)){
            let requiredData = FULFILLMENT_SERVICES[key];
            if(!Object.keys(this.state.data.fulfillment).includes(key)){
                this.state.data.fulfillment[key] = requiredData;
            }
        }
    }

    handleAccordionClick = (e, props) => {
        this.setState({expanded: (this.state.expanded == props.index ? -1 : props.index)});
    }

    render(){
        return (
            <Modal trigger={
                <Button circular icon="setting" labelPosition="left" content="Settings" />
            }>
                <Header icon="pencil" content="User Settings" />
                <Modal.Content>
                    <Header icon="lightning" content="Fulfillment" />
                    <Accordion styled fluid>
                        <Accordion.Title active={this.state.expanded == 0} index={0} onClick={this.handleAccordionClick}>
                            <Icon name="dropdown" />
                            Particle.io
                        </Accordion.Title>
                        <Accordion.Content active={this.state.expanded == 0}>
                            <Header><Icon name="key" />Access Token</Header>
                            <Input placeholder="Non-expiring access token" value={this.state.data.fulfillment["particle.io"].access_token} onChange={
                                (e, props) => {
                                    let newData = this.state.data;
                                    newData.fulfillment["particle.io"].access_token = props.value;
                                    this.setState({data: newData});
                                }
                            } />
                            <Message info>Find your non-expiring access token in the settings menu of the Particle IDE.</Message>
                        </Accordion.Content>
                    </Accordion>
                </Modal.Content>
                <Modal.Actions>
                    <Button icon="save" positive labelPosition="left" content="Save" onClick={() => {
                        this.props.onSave(this.state.data);
                    }} />
                </Modal.Actions>
            </Modal>
        );
    }
}