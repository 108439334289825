import React from "react";
import {HashLink as Link} from "react-router-hash-link";
import {Header, Loader, Dimmer, Icon, Button, Divider} from "semantic-ui-react";
import firebase from "firebase";
import {FIREBASE_CONFIG, randomString} from "./const";
import queryString from "query-string";

export default class OAuth extends React.Component {
    constructor(props){
        super(props);
        this.state = {loading: true, appName: "this app", error: ""};
        if(firebase.apps.length == 0) firebase.initializeApp(FIREBASE_CONFIG);
        this.db = firebase.firestore();
        firebase.auth().onAuthStateChanged((u) => {
            if(u){
                this.user = u;
                this.params = queryString.parse(window.location.search);
                console.log(this.params);
                if(this.params.client_id == undefined || this.params.client_id == "" || this.params.redirect_uri == undefined || this.params.redirect_uri == "" || this.params.state == undefined || this.params.state == "" || this.params.response_type == ""){
                    this.setState({error: "bad_params"});
                }else{
                    this.setState({loading: false});
                }
            }else{
                window.location.replace("/auth?dest=" + encodeURIComponent(window.location));
            }
        });
    }
    render(){
        console.error("Error in oauth flow: " + this.state.error);
        if(this.state.error !== ""){
            let header;
            let subheader;
            console.error("this.state.error: " + this.state.error);
            switch(this.state.error){
                case "bad_params":
                    header = "Error: Invalid or Missing Parameters"
                    subheader = <span>This probably happened because you directly navigated here. If so, you can <Link to="/">return home</Link>.</span>;
                    break;
                case "fetch_failed":
                    header = "Error: Could not Contact Database"
                    subheader = <span>An error occured while fetching data from the database. Please check your Internet connection and try again.</span>;
                    break;
                case "bad_params":
                    header = "Error: Could not Contact Database"
                    subheader = <span>An error occured while sending data to the database. Please check your Internet connection and try again.</span>;
                    break;
                default:
                    header = "Unable to Link Account";
                    subheader = "There was an error. Please try again later.";
            }
            return (
                <div className="fullPagePadded" >
                    <Header as="h1" icon size="massive"><Icon name="x" />{header}</Header>
                    <Header as="h2" size="huge">{subheader}</Header>
                </div>
            );
        }
        if(this.state.loading){
            return (
                <Dimmer active>
                    <Loader />
                </Dimmer>
            );
        }

        let appName;
        switch(this.params.client_id){
            case "googleassistant":
                appName = "Google";
                break;
            default:
                appName = "This app";
                break;
        }
        return (
            <div className="fullPagePadded">
                <Header as="h1" icon size="massive"><Icon name="handshake" />{appName} wants to access your data.</Header>
                <Header as="h2" size="huge">If you grant this app access, it will be able to read and change the state of your devices.</Header>
                <Divider hidden section />
                <Button content="Grant Access" icon="right arrow" positive labelPosition="right" size="massive" onClick={() => {
                    this.db.collection("users").doc(this.user.uid).get().then((doc) => {
                        const newCode = this.user.uid + "-" + randomString();
                        this.db.collection("users").doc(this.user.uid).update({
                            ["oauth.authCodes." + newCode]: {
                                client: this.params.client_id,
                                authorizedOn: new Date()
                            }
                        }).then(() => {
                            window.location.replace("https://oauth-redirect.googleusercontent.com/r/" + FIREBASE_CONFIG.projectId + "?code=" + newCode + "&state=" + this.params.state);
                        }, (e) => {
                            console.error(e);
                            this.setState({error: "update_failed"});
                        });
                        
                    }, (e) => {
                        console.error(e);
                        
                        this.setState({error: "fetch_failed"});
                    });
                    
                }}/>
                <Divider hidden />
                <Button icon="sign out" labelPosition="left" content="Change Account" size="small" onClick={() => {
                    firebase.auth().signOut().then(() => {
                        this.setState({loading: true});
                        window.location.replace("/auth?dest=" + encodeURIComponent(window.location));
                    }, (e) => {
                        console.error(e);
                    });
                }} />
            </div>
        );
        
    }
}