import React, { Component } from 'react';
import "semantic-ui-css/semantic.min.css";
import './App.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

//Import page components
import Home from "./Home";
import Auth from "./Auth";
import OAuth from "./OAuth";
import Dashboard from './Dashboard';
import Docs from "./Docs";
import AlphaRegistration from './AlphaRegistration';

class App extends Component {
  render() {
    return (
      <div>

        <Router>
          <div>
            <Route path="/" exact component={Home} />
            <Route path="/home" component={Home} />
            <Route path="/auth" component={Auth} />
            <Route path="/oauth" component={OAuth} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/docs" component={Docs} />
            <Route path="/documentation" component={Docs} />
            <Route path="/help" component={Docs} />
            <Route path="/registration" component={AlphaRegistration} />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
