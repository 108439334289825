import React from "react";
import {Button, Modal, Dropdown, Input, Header, Segment, Popup, TextArea, Message, Form} from "semantic-ui-react";
import {DEVICE_TYPES, DEVICE_TRAITS, IsJsonString} from "./const";
import {HashLink as Link} from "react-router-hash-link";
import * as FulfillmentSettings from "./FulfillmentSettings";


export default class EditModal extends React.Component {

    setFulfillmentData = (data) => {
        let newData = this.state.data;
        newData.fulfillment = data;
        this.setState({data: newData});
    }

    constructor(props){
        super(props);
        this.state = {open: false, data: this.props.device, attributes: JSON.stringify(this.props.device.attributes), attributesError: false};
        this.FULFILLMENT_SERVICES = {
            "dummy": <FulfillmentSettings.Dummy onChange={this.setFulfillmentData} data={this.state.data.fulfillment} />,
            "particle.io": <FulfillmentSettings.Particle onChange={this.setFulfillmentData} data={this.state.data.fulfillment} />
        };;
    }

    render(){
        return (
            <Modal trigger={<Button circular secondary floated="right" icon="pencil" size="large" onClick={() => {this.setState({open: true})}} />} open={this.state.open} onClose={() => {this.setState({open: false})}}>
                <Header icon="pencil" content={"Edit " + this.state.data.name} />
                <Link to="/docs#edit-device" target="_blank"><Button floated="right" circular icon="help" style={{marginRight: "15px", marginTop: "15px"}} /></Link>
                <Modal.Content>
                    
                    <Header icon="tag" content="Name" />
                        <Input placeholder="Name your device" error={this.state.data.name == ""} value={this.state.data.name} onChange={
                            (e, props) => {
                                let newData = this.state.data;
                                newData.name = props.value;
                                this.setState({data: newData});
                            }
                        } />
                    <Header icon="cube" content="Device Type" />
                        <Dropdown placeholder="Device type" search selection value={this.state.data.type} options={
                            DEVICE_TYPES.map((n, i) => {
                                return (
                                    {
                                        key: n.toUpperCase(),
                                        value: n.toUpperCase(),
                                        text: n.replace("_", " ")
                                    }
                                );
                            })
                        } onChange={
                            (e, props) => {
                                let newData = this.state.data;
                                newData.type = props.value;
                                this.setState({data: newData});
                            }
                        } />
                    <Header icon="lightning" content="Fulfillment Service" />
                        <Dropdown placeholder="Fulfillment service" search selection value={this.state.data.fulfillment.service} options={
                            Object.keys(this.FULFILLMENT_SERVICES).map((key) => {
                                return (
                                    {
                                        key: key,
                                        value: key,
                                        text: key
                                    }
                                );
                            })
                        } onChange={
                            (e, props) => {
                                let newData = this.state.data;
                                newData.fulfillment.service = props.value;
                                this.setState({data: newData});
                            }
                        } />
                        {this.FULFILLMENT_SERVICES[this.state.data.fulfillment.service]}
                    <Header icon="superpowers" content="Traits" />
                    <Dropdown placeholder="Traits" search multiple selection value={this.state.data.traits} options={
                        DEVICE_TRAITS.map((val) => {
                            return (
                                {
                                    key: val,
                                    value: val,
                                    text: val
                                }
                            );
                        })
                    } onChange={
                        (e, props) => {
                            let newData = this.state.data;
                            newData.traits = props.value;
                            this.setState({data: newData});
                        }
                    } />
                    <Header icon="cubes" content="Attributes" />
                    {this.state.attributesError ? <Message header="JSON data error" content="Please make sure your JSON data is typed correctly." icon="exclamation" error /> : null}
                    <Form>
                        <TextArea autoHeight placeholder="Type your attributes, in JSON format" value={this.state.attributes} onChange={(event, props) => {
                            let text = props.value;
                            if(text.length < 2) text = "{}";
                            if(text[0] != "{") text = "{" + text;
                            if(text[text.length - 1] != "}") text += "}";
                            this.setState({attributes: text});
                        }} />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Popup on="click" style={{textAlign: "center"}} trigger={
                        <Button floated="left" negative labelPosition="left" icon="trash" content="Delete device" />
                    }>
                        <Header as="h3">Really delete this device?</Header>
                        <p>This action is irreversible.</p>
                        <Button negative labelPosition="left" icon="trash" content="Delete device" onClick={
                            () => {
                                this.props.onDelete();
                                this.setState({open: false});
                            }
                        }/>
                    </Popup>
                    <Button labelPosition="left" icon="x" content="Cancel" onClick={() => {this.setState({open: false})}} />
                    <Button positive labelPosition="left" icon="save" content="Save" onClick={
                        () => {
                            let newData = this.state.data;
                            try {
                                newData.attributes = JSON.parse(this.state.attributes);
                            } catch (e) {
                                this.setState({attributesError: true});
                                return;
                            }
                            this.props.onSave(newData);
                            this.setState({open: false, attributesError: false});
                        }
                    } />
                </Modal.Actions>
            </Modal>
        );
    }
}