import React, {Component, createRef} from "react";
import {HashLink as Link} from "react-router-hash-link";
import {Header, Icon, Segment, Divider, List, Table, Image, Grid, Button, Accordion} from "semantic-ui-react";
import FulfillmentDiagram from "./resources/FulfillmentDiagram.svg";

const ANCHORS = [
    {
        text: "Frequently Asked Questions",
        to: "#faq",
        children: [
            {
                text: "What does DevHome Do?",
                to: "#faq-what"
            },
            {
                text: "Who Can Use DevHome?",
                to: "#faq-who"
            },
            {
                text: "How Much does DevHome Cost?",
                to: "#faq-cost"
            }
        ]
    },
    {
        text: "Setup",
        to: "#setup",
        children: [
            {
                text: "Linking your Google Account",
                to: "#link-google"
            }
        ]
    },
    {
        text: "Using the Dashboard",
        to: "#dashboard",
        children: [
            {
                text: "Create a Device",
                to: "#create-device"
            },
            {
                text: "Edit a Device (Device Fields Guides)",
                to: "#edit-device"
            },
            {
                text: "Delete a Device",
                to: "#delete-device"
            }
        ]
    },
    {
        text: "Fulfillment",
        to: "#fulfillment",
        children: [
            {
                text: "Dummy (No Fulfillment)",
                to: "#fulfillment-dummy"
            },
            {
                text: "Particle.io",
                to: "#fulfillment-particle"
            }
        ]
    }
];

export default class Docs extends Component {
    constructor(props){
        super(props);
        this.state = {openAccordion: -1};
    }

    render(){



        return (
            <div className="fullPagePadded">
                <Header size="huge" icon><Icon name="file alternate outline" />Documentation</Header><br />
                <Link to="/dashboard"><Button circular positive icon="right arrow" labelPosition="right" content="Go To Dashboard" /></Link>
                <Divider section />
                
                <Grid divided>
                    <Grid.Row>
                        <Grid.Column style={{textAlign: "left"}} width={3}>
                            <Header size="medium" style={{textAlign: "center"}}>DevHome Reference and Documentation</Header>
                            <Accordion style={{fontSize: "15px"}}>
                                {
                                    ANCHORS.map((anchor, index) => {
                                        return (
                                            <span>
                                                <Accordion.Title index={index} active={this.state.openAccordion == index} onClick={() => {
                                                    this.setState({openAccordion: this.state.openAccordion == index ? -1 : index});
                                                }}><Icon name="dropdown" />{anchor.text}</Accordion.Title>
                                                <Accordion.Content active={this.state.openAccordion == index} style={{paddingLeft: "30px"}}>
                                                    <Link to={anchor.to} style={{display: "block", paddingBottom: "5px"}}>{anchor.text}</Link>
                                                    {
                                                        anchor.children.map((child) => {
                                                            return (
                                                                <Link to={child.to} style={{display: "block", paddingBottom: "5px"}}>{child.text}</Link>
                                                            );
                                                        })
                                                    }
                                                </Accordion.Content>
                                            </span>
                                        );
                                    })
                                }
                            </Accordion>
                        </Grid.Column>
                        <Grid.Column style={{textAlign: "left", paddingLeft: "20px"}} width={13}>
                            <Header style={{fontSize: "40px"}} id="faq">Frequently Asked Questions</Header>
                            <Header size="huge" id="faq-what">What Does DevHome Do?</Header>
                            <p>DevHome is simply a middleman between the Google Assistant and another API. The image below sums it up:</p>
                            <Image size="massive" rounded src={FulfillmentDiagram} centered alt="Diagram of the path of a DevHome request" />
                            <p>Usually, if you want to integrate a Smart Home device with the Google Assistant, you would have to make your own app, database, and server code. DevHome eliminates these middle steps, so you can focus on developing your actual device without dealing with configuration.</p>
                            <Header size="huge" id="faq-who">Who Can Use DevHome?</Header>
                            <p>DevHome was made for hobbyists to make their own smart home devices, and for companies/startups to prototype early iterations of their devices.</p>
                            <p>DevHome should not be used for production devices; it is not for devices that are sold or distributed to end users or customers.</p>
                            <p>Because of this focus on personal-scale development, DevHome does not have an API or OAuth endpoint that your app or device can use. Anyone using DevHome will have to manually configure their device.</p>
                            <p><strong>Please do not use DevHome for commercial or large-scale use, beyond yourself or a group of friends/coworkers.</strong></p>
                            <Header size="huge" id="faq-cost">How Much does DevHome Cost?</Header>
                            <p>DevHome is currently free to make it as accessible as possible to hobbyists. We do not plan to make the core functionality a paid service.</p>
                            <p>If we find that our server costs are too much to maintain, we may impose reasonable quotas only to prevent spamming and overloading. Everyday hobbyist use should be well under any possible imposed quota.</p>
                            <Divider />
                            <Header style={{fontSize: "40px"}} id="setup">Setup</Header>
                            <Header size="huge" id="link-google">Linking your Google Account</Header>
                            <p>To have the Google Assistant control your DevHome devices, follow the simple steps.</p>

                            <List relaxed="very" ordered size="big">
                                <List.Item>
                                    Download the Google Home app from the <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.chromecast.app&hl=en_US">Google Play Store</a> or the <a href="https://itunes.apple.com/us/app/google-home/id680819774?mt=8">App Store</a>. Set up the Home app by signing in.
                                </List.Item>
                                <List.Item>
                                    Link the DevHome service by tapping <b>Add</b> on the top of the home screen. Tap on <b>Set up device</b>, tap on <b>Have something already set up?</b>, then tap <b>Dev Home</b> under "Add new."
                                </List.Item>
                                <List.Item>
                                    You should be redirected to the DevHome website. Sign in if you're not already, and click <b>Grant Access</b>. You will be redirected to the app.
                                </List.Item>
                                <List.Item>
                                    If you have devices registered with DevHome, add them to rooms on the next screen in the app.
                                </List.Item>
                            </List>
                            <Divider />
                            <Header style={{fontSize: "40px"}} id="dashboard">Using the Dashboard</Header>
                            <Header size="huge" id="create-device">Create a Device</Header>
                            <p>Create a new device by clicking the green plus button. A device called "New Device" will be added; edit it in the next section.</p>
                            <Header size="huge"id="edit-device">Edit a Device</Header>
                            <p>Edit a device by clicking the black pencil button next to a device. After your changes are made, click <b>Save</b> on the bottom of the popup.</p>
                            <Header size="large">Device Properties</Header>
                            <Table celled size="large">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Property</Table.HeaderCell>
                                        <Table.HeaderCell>Description</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Name</Table.Cell>
                                        <Table.Cell>What your device will be called by Google Assistant.</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Device Type</Table.Cell>
                                        <Table.Cell>The type of a device defines what icon it gets in the Google Home app, and enables commands such as "turn off the <em>lights</em>" or "turn on all the <em>sprinklers</em>." View different device types <a href="https://developers.google.com/actions/smarthome/guides/">on the Google Assistant documentation</a>.</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Fulfillment Service</Table.Cell>
                                        <Table.Cell>The external service that DevHome will use to execute any requests. See <Link to="#fulfillment">Fulfillment</Link>.</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Device Traits</Table.Cell>
                                        <Table.Cell>Select multiple traits that your device is capable of handling. For example, a light may have an "OnOff" trait, if it is dimmable a "Brightness" trait, and if it is a color bulb a "ColorSetting" trait. View a list of traits at <a href="https://developers.google.com/actions/smarthome/traits/">the Google Assistant documentation</a>.</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Attributes</Table.Cell>
                                        <Table.Cell>Enter required attributes in JSON format. View the applicable attributes by clicking on a trait on the <a href="https://developers.google.com/actions/smarthome/traits/">Google Assistant documentation</a>.</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            <Header size="huge" id="delete-device">Delete a Device</Header>
                            <p>To delete a device, click on the edit button on the device you want to delete and press <b>Delete device</b> on the bottom left of the popup.</p>
                            <Header size="huge">Update Google Assistant after Editing Devices</Header>
                            <p>After editing, adding, or deleting devices, a message saying <b>Sync devices</b> will appear. Click the message to send any changes to the Google Home app.</p>
                            <Divider />
                            <Header style={{fontSize: "40px"}} id="fulfillment">Fulfillment</Header>
                            <p>DevHome acts as a middleman that processes requests from Google Assistant. It then sends a chosen fulfillment service a request.</p>
                            <Image size="massive" rounded src={FulfillmentDiagram} centered alt="Diagram of the path of a DevHome request" />
                            <p>After you select the fulfillment service, more options specific to that service will appear. Below is a description of said options.</p>
                            <Header size="huge" id="fulfillment-dummy">Dummy (No Fulfillment)</Header>
                            <p>The dummy fulfillment is for test devices that have no real-world counterpart. When a request is made, it is thrown away; DevHome does not make a request to a fulfillment API.</p>
                            <p>For testing purposes, however, you can specify the value DevHome will return to Google Assistant, in JSON format. For example, if I have a <i>Light</i> device with trait <i>OnOff</i>, you can enter <code>&#123;"on":true&#125;</code> to make the Google Assistant say "The light is on."</p>
                            
                            <Table celled size="large">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Property</Table.HeaderCell>
                                        <Table.HeaderCell>Description</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Return State</Table.Cell>
                                        <Table.Cell>A value returned to the Google Assistant on a QUERY request, in JSON format. View supported return states by going to the <a href="https://developers.google.com/actions/smarthome/traits/">Google Assistant documentation</a> and selecting your device's traits.</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            <Header size="huge" id="fulfillment-particle">Particle.io</Header>
                            <p>DevHome integrates seamlessly with <a href="https://particle.io">Particle</a> to make your Internet of things devices smart. It works with any cloud-connected Particle board.</p>
                            <p>Tip: you can use the same code for multiple devices, as long as they are wired the same way. Just use different device ids in the DevHome dashboard.</p>
                            <Header size="large">Configure DevHome</Header>
                            <List relaxed="very" ordered size="big">
                                <List.Item>
                                    In the DevHome dashboard, click on settings to access your user settings. Expand the <b>particle.io</b> section.
                                </List.Item>
                                <List.Item>
                                    Go to the <a to="https://build.particle.io/build">Particle IDE</a>, click on the settings icon, and copy the access token. Go back to DevHome and paste it in the field called "access token." If you ever reset your Particle access token, you will have to repeat these steps.
                                </List.Item>
                                <List.Item>
                                    In the dashboard, open the editing window for the device you want to edit. Change the fulfillment service to <b>particle.io</b>.
                                </List.Item>
                                <List.Item>
                                    Enter the Particle Device ID of the Particle board that DevHome should tell when it gets a request for that device. Next, you will have to upload relevant code to the Particle board.
                                </List.Item>
                            </List>
                            <Table celled size="large">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Property</Table.HeaderCell>
                                        <Table.HeaderCell>Description</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Device ID</Table.Cell>
                                        <Table.Cell>The Device ID of the corresponding Particle board for this device. In <a href="https://build.particle.io/build">Particle IDE</a>, click the devices icon, select the device in question, and copy the device id.</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            <Header size="large">Add DevHome Code</Header>
                            <p>Your code needs two endpoints that DevHome will use: a <a href="https://docs.particle.io/reference/device-os/firmware/photon/#particle-variable-">cloud variable</a> named "devhome," and <a href="https://docs.particle.io/reference/device-os/firmware/photon/#particle-function-">cloud function</a> also called "devhome." The cloud function will be read on a QUERY request ("Is device ___ on?") and the function will be called on an EXECUTE request ("Turn device ___ off.")</p>
                            <p>Theoretically, you can omit either the function or the variable. Omitting the function will make your device read-only, and omitting the variable will make the device write-only.</p>
                            <Header size="medium">Cloud Function</Header>
                            <p>All cloud functions take in a <i>string</i> and return an <i>int</i>. The string is an array in JSON form.</p>
                            <pre className="codeBlock">
                            {`
[
    {
        "cmd": "OnOff",
        "val": {
            "on": false
        }
    }
]
                            `}
                            </pre>
                            <Table celled size="large">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Property</Table.HeaderCell>
                                        <Table.HeaderCell>Description</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>cmd</Table.Cell>
                                        <Table.Cell>The device command described <a href="https://developers.google.com/actions/smarthome/traits/">here</a>, but with the "actions.devices.commands." prefix removed.</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>val</Table.Cell>
                                        <Table.Cell>The params of the command, described <a href="https://developers.google.com/actions/smarthome/traits/">here</a>, in JSON format.</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            <p>The function should return an integer. Usually, the value <code>1</code> is used to indicate a success, but any number can be returned, and DevHome does not read the response.</p>
                            <Header size="medium">Cloud Variable</Header>
                            <p>There should be a cloud variable of type String called "devhome." It must be updated whenever there is a change in device state, whether it is caused by DevHome or not. Since a cloud operation is only made when an agent gets the variable, not when your device sets the variable, you can set it as often as needed.</p>
                            <p>The variable is simply a JSON tree of the current states of the device, as described <a href="https://developers.google.com/actions/smarthome/traits/">here</a> (click on each trait you're using to find the valid states). The variable should always contain an up-to-date stringified JSON object.</p>
                            <pre className="codeBlock">
                            {`
// Example value for a device with only an OnOff trait
{
    on: true
}
                            `}
                            </pre>
                            <Header size="medium">Example Implementation</Header>
                            <p>We will use <a href="https://github.com/menan/SparkJson">the SparkJson library</a> to decode the JSON data. SparkJson is a fork of <a href="https://arduinojson.org/">ArduinoJson</a>, but since it isn't updated, it uses ArduinoJson version 5.</p>
                            <pre className="codeBlock">
                                {`
// Example implementation of DevHome function endpoint
// This example code is written for a device with only an OnOff trait.
#include <SparkJson.h> //Import the library in the Particle IDE by clicking on the Libraries icon
bool isOn = false; //Global variable tracking if the device is on; we'll need this later when we implement the cloud variable
String devHomeStatus = "{}"; //A JSON representation of the current status
int DevHomeEndpoint(String datastring){
    char data[200]; //Increase the number 200 to something larger if you have more traits on your device. Generally 200 should be enough
    datastring.toCharArray(data, 200); //Convert the string of data into a char array for ArduinoJson. This 200 should be the same number as above
    StaticJsonBuffer<500> jsonBuffer; //The 500 number should be a little larger than the 200 number; increase if the expected JSON is longer
    JsonArray& root = jsonBuffer.parseArray(data); //Parse the JSON into a usable object
    for(JsonObject& delta : root){ //Loop through each command, and get the change ("delta") in state
        const char* command = delta["cmd"]; //The command name
        JsonObject& vals = delta["val"]; //The parameters of the command
        
        if(strcmp(command,"OnOff") == 0){ //Check if the command is OnOff. Copy this if statement for each command that the device supports
        isOn = true;
        // TODO: Actually do something, for example activate a motor, turn on a light, etc   
        }
    }
    UpdateDevHomeStatus();
    return 1; //Success
}
void UpdateDevHomeStatus(){ //Update the cloud variable state. This should be called ANY TIME the state of the device is changed, not just by DevHome
    char output[200]; //Increase the size it the expected JSON is going to be lots of characters
    StaticJsonBuffer<500> buffer; //The size (currently 500) should be a little above the size of output
    JsonObject& obj = buffer.createObject(); //Create the new object
    obj["on"] = isOn; //If you wish to have more traits and supported commands, make sure to copy this line
    obj.printTo(output, 200); //Serialize the object. If you changed the size of output, change this size to match
    devHomeStatus = String(output); //Convert the output to a string. When devHomeStatus is set, the cloud variable is also automatically set
}
void setup(){
    Particle.function("devhome", DevHomeEndpoint);
    Particle.variable("devhome", devHomeStatus);
    UpdateDevHomeStatus(); //Update the variable with initial state
}
                                `}
                            </pre>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                
            </div>
        );
    }
}