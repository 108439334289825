import React from "react";
import {HashLink as Link} from "react-router-hash-link";
import {Header, Icon, Button, Divider, Grid, Image} from "semantic-ui-react";
import logo from "./resources/Logo.png";

export default class Home extends React.Component {
    render(){
        return (
            <div className="fullPagePadded">
                <Image circular src={logo} size="small" centered />
                <Header as="h1" style={{fontSize: "40px"}}>Welcome DevHome.</Header> 
                <Header as="h2" size="huge">DevHome is the easiest way for hobbyists to effortlessly create smart home devices.</Header>
                <Divider hidden style={{marginTop: "30px"}} />
                <Link to="registration"><Button content="Become an Alpha Tester" icon="right arrow" positive labelPosition="right" size="massive" /></Link>
                <div style={{paddingTop: "10px"}} />
                <Link to="/dashboard"><Button content="Already a tester? Go to Dashboard" icon="dashboard" primary labelPosition="left" /></Link>
                <div style={{paddingTop: "10px"}} />
                <Link to="/docs"><Button content="View Documentation" icon="file alternate outline" labelPosition="left" /></Link>
                <Divider section hidden />
                <Header as="h3">Read <a href="https://medium.com/@yoonicode/smart-home-misadventures-7aff7e84e50b">the story behind DevHome</a> on Medium</Header>
                <Divider section hidden />
                <Grid columns={3} divided>
                    <Grid.Row>
                        <Grid.Column>
                            <Header icon><Icon name="handshake" />Integrates Seamlessly</Header>
                            <p style={{fontSize: "20px"}}>DevHome integrates with Google Assistant and different APIs such as <a href="https://particle.io">Particle.io</a>, minimizing the amount of tedious API configuration you have to do.</p>
                        </Grid.Column>
                        <Grid.Column>
                            <Header icon><Icon name="code" />Made For Hobbyists</Header>
                            <p style={{fontSize: "20px"}}>Use DevHome to make your own smart home devices, without needing to maintain infrastructure.</p>
                        </Grid.Column>
                        <Grid.Column>
                            <Header icon><Icon name="gift" />Completely Free!</Header>
                            <p style={{fontSize: "20px"}}>DevHome is <Link to="/docs#faq-cost">100% free for personal and private prototype use</Link>, with no limitations, restrictions, or catches.</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}