export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDzxf5jicdP4anIiUtHgZORZRkAvb-ondE",
  authDomain: "devhome-2a126.firebaseapp.com",
  databaseURL: "https://devhome-2a126.firebaseio.com",
  projectId: "devhome-2a126",
  storageBucket: "devhome-2a126.appspot.com",
  messagingSenderId: "492548940326"
};

export function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export function randomString(length = 16) {
    var text = "";
    var possible = "mABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    return text;
}

export const DEVICE_TYPES = [
  "AC_Unit",
  "AirFreshener",
  "AirPurifier",
  "Awning",
  "Bathtub",
  "Bed",
  "Blender",
  "Blinds",
  "Boiler",
  "Camera",
  "Carbon_Monoxide_Detector",
  "Charger",
  "Closet",
  "Coffee_Maker",
  "Cooktop",
  "Curtain",
  "Dehumidifier",
  "Dehydrator",
  "Dishwasher",
  "Door",
  "Drawer",
  "Dryer",
  "Fan",
  "Faucet",
  "Fireplace",
  "Fryer",
  "Garage",
  "Gate",
  "Grill",
  "Heater",
  "Hood",
  "Humidifier",
  "Kettle",
  "Light",
  "Lock",
  "RemoteControl",
  "Mop",
  "Mower",
  "Microwave",
  "Multicooker",
  "Network",
  "Outlet",
  "Oven",
  "Pergola",
  "PetFeeder",
  "PressureCooker",
  "Radiator",
  "Refrigerator",
  "Router",
  "Scene",
  "Sensor",
  "SecuritySystem",
  "SetTop",
  "Shutter",
  "Shower",
  "Smoke_Detector",
  "SousVide",
  "Sprinkler",
  "Switch",
  "TV",
  "Thermostat",
  "Vacuum",
  "Valve",
  "Washer",
  "WaterHeater",
  "WaterPurifier",
  "WaterSoftener",
  "Window",
  "YogurtMaker"
]

export const DEVICE_TRAITS = [
  "AppSelector",
  "ArmDisarm",
  "Brightness",
  "CameraStream",
  "ColorSetting",
  "Cook",
  "Dispense",
  "Dock",
  "EnergyStorage",
  "FanSpeed",
  "Fill",
  "HumiditySetting",
  "InputSelector",
  "LightEffects",
  "Locator",
  "LockUnlock",
  "MediaState",
  "Modes",
  "NetworkControl",
  "OnOff",
  "OpenClose",
  "Reboot",
  "Rotation",
  "RunCycle",
  "SensorState",
  "Scene",
  "SoftwareUpdate",
  "StartStop",
  "StatusReport",
  "TemperatureControl",
  "TemperatureSetting",
  "Timer",
  "Toggles",
  "TransportControl",
  "Volume"
]

export function IsJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}