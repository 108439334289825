import React from "react";
import {HashLink as Link} from "react-router-hash-link";
import {Header, Button, Divider, Icon, Loader, Dimmer, Segment, Message, Input} from "semantic-ui-react";
import firebase from "firebase";
import {FIREBASE_CONFIG, validateEmail, randomString} from "./const";
import queryString from "query-string";

export default class Auth extends React.Component {

    constructor(props){
        super(props);
        this.state = {invalidDest: false, loading: false, buttonLoading: false, error: "", username: "", password: ""};
        this.redirectUrl = queryString.parse(window.location.search).dest;
        if(this.redirectUrl == "" || this.redirectUrl == undefined){
            this.state.invalidDest = true;
            this.state.loading = false;
            return;
        }
        if(firebase.apps.length == 0) firebase.initializeApp(FIREBASE_CONFIG);
        this.db = firebase.firestore();

    }

    signIn = () => {
        this.setState({buttonLoading: true});
        if(this.state.password == "" || !validateEmail(this.state.username)){
            this.setState({error: "Email or password incorrect. Please check your login info and try again.", buttonLoading: false});
            return;
        }
        firebase.auth().signInWithEmailAndPassword(this.state.username, this.state.password).then((user) => {
            window.location.replace(this.redirectUrl);
        }, (e) => {
            console.error(e);
            switch(e.code){
                case "auth/invalid-email":
                    this.setState({error: "The email you entered is invalid. Check for mistakes and try again.", buttonLoading: false});
                    break;
                case "auth/user-disabled":
                    this.setState({error: "Your account has been disabled. Please contact us for assistance.", buttonLoading: false});
                    break;
                case "auth/user-not-found":
                    this.signUp();
                    return;
                case "auth/wrong-password":
                    this.setState({error: "The password you entered is incorrect. Check for mistakes and try again.", buttonLoading: false});
                    break;
                default:
                    this.setState({error: "An unknown error occured. Please try again later.", buttonLoading: false});
                    break;
            }
        });   
    };
    signUp = () => {
        this.setState({buttonLoading: true});
        if(this.state.password == "" || !validateEmail(this.state.username)){
            this.setState({error: "Email or password incorrect. Please check your login info and try again.", buttonLoading: false});
            return;
        }
        firebase.auth().createUserWithEmailAndPassword(this.state.username, this.state.password).then((res) => {
            this.db.collection("users").doc(res.user.uid).set({
                fulfillment: {},
                devices: {},
                oauth: {
                    authCodes: {},
                    refreshTokens: {},
                    accessTokens: {}
                },
                needsSync: false
            }).then(() => {
                console.log("hi!");
                window.location.replace(this.redirectUrl);
            }, (e) => {
                console.error(e);
                this.setState({error: "An error occured while creating your account. Please try again later.", buttonLoading: false});
            });
            
        }, (e) => {
            console.error(e);
            switch(e.code){
                case "auth/invalid-email":
                    this.setState({error: "The email you entered is invalid. Check for mistakes and try again.", buttonLoading: false});
                    break;
                case "auth/email-already-in-use":
                    this.signIn();
                    return;
                case "auth/operation-not-allowed":
                    this.setState({error: "Signing up is disabled at this time. Please try again later.", buttonLoading: false});
                    break;
                case "auth/weak-password":
                    this.setState({error: "Your password is too weak. Please try again with a stronger password.", buttonLoading: false});
                    break;
                default:
                    this.setState({error: "An unknown error occured. Please try again later.", buttonLoading: false});
                    break;
            }
        });
    };

    render(){
        if(this.state.loading){
            return (
                <Dimmer active>
                    <Loader />
                </Dimmer>
            );
        }
        if(this.state.invalidDest){
            return (
                <div className="fullPagePadded" >
                    <Header as="h1" icon size="massive"><Icon name="x" />Error: Invalid Parameter</Header>
                    <Header as="h2" size="huge">This probably happened because you directly navigated here. If so, you can <Link to="/">return home</Link>.</Header>
                </div>
            );
        }
        return (
            <div className="fullPagePadded">
                <Header as="h1" icon size="huge"><Icon name="sign in" />Welcome!</Header>
                <Divider hidden style={{marginTop: "30px"}} />
                <Segment compact style={{margin: "0 auto", padding: "20px 5vw"}}>
                    <Header as="h2" size="huge">Sign In/Sign Up</Header>
                    <Divider />
                    <Input placeholder="Email" disabled={this.state.buttonLoading} error={!validateEmail(this.state.username)} size="huge" type="email" value={this.state.username} onChange={(e, props) => {
                        this.setState({username: props.value});
                    }} />
                    <Divider hidden />
                    <Input placeholder="Password" disabled={this.state.buttonLoading} error={this.state.password == ""} size="huge" type="password" value={this.state.password} onChange={(e, props) => {
                        this.setState({password: props.value});
                    }} />
                    <Divider />
                    <Button.Group size="huge">
                        <Button loading={this.state.buttonLoading} content="Sign In" onClick={this.signIn}/>
                        <Button.Or />
                        <Button loading={this.state.buttonLoading} primary content="Sign Up" onClick={this.signUp}/>
                    </Button.Group>
                    {
                        this.state.error == "" ? null : (
                            <Message error icon="x" content={this.state.error} />
                        )
                    }
                </Segment>
                
            </div>
        );
    }
}