import React from "react";
import {HashLink as Link} from "react-router-hash-link";
import {Header, Icon, Button, Divider, Dropdown, Input} from "semantic-ui-react";
import logo from "./resources/Logo.png";
import {validateEmail} from "./const";

const platformOptions = [
    {key: "particle", text: "Particle.io", value: "particle"},
    {key: "raspberrypi", text: "Raspberry Pi", value: "raspberrypi"},
    {key: "arduino", text: "Arduino", value: "arduino"},
    {key: "node", text: "Node.js Server", value: "nodejs"},
    {key: "bluetooth", text: "Bluetooth device", value: "bluetooth"},
    {key: "zigbee", text: "Zigbee", value: "zigbee"}
]

export default class AlphaRegistration extends React.Component {
    constructor(props){
        super(props);
        this.state = {flowProgress: 0, platformDropdown: [], platformOther: "", email: ""};
    }


    render(){
        switch(this.state.flowProgress){
            case 0:
                return (
                    <div className="fullPagePadded" style={{paddingLeft: "50px", paddingRight: "50px"}}>
                        
                        <Header as="h1" style={{fontSize: "50px"}}>Alpha Program Registration</Header>
                        <p style={{fontSize: "25px"}}>Hello! Thanks for your interest in becoming a alpha tester. Since DevHome has a very specific user demographic, we need to make sure DevHome is right for you. Please answer this very short form to sign up.</p>
                        <Divider hidden />
                        <Button size="massive" positive labelPosition="right" icon="right arrow" content="Continue" onClick={() => {
                            this.setState({flowProgress: 1});
        
                        }} />
                    </div>
                );
            case 1:
                return (
                    <div className="fullPagePadded" style={{paddingLeft: "50px", paddingRight: "50px"}}>
                        
                        <Header as="h1" style={{fontSize: "50px"}}>Promise of Fair Use</Header>
                        <p style={{fontSize: "25px"}}>DevHome was made with hobbyists specifically in mind. We are only able to provide DevHome as a free service if our users responsibly use it. You may use DevHome for personal use or among a small group of friends. If you use DevHome commercially, you may only use it in your prototyping phase; once you get your first customer or start distributing the product, you must create your own API interface.</p>
                        <p style={{fontSize: "30px"}}>Please promise that you will responsibly and fairly use DevHome so we can continue providing free service.</p>
                        <Divider hidden />
                        <Button size="massive" positive labelPosition="right" icon="right arrow" content="I agree" onClick={() => {
                            this.setState({flowProgress: 2});
        
                        }} />
                    </div>
                );
            case 2:
                return (
                    <div className="fullPagePadded" style={{paddingLeft: "50px", paddingRight: "50px"}}>
                        <Header as="h1" style={{fontSize: "50px"}}>Question 1/3</Header>
                        <p style={{fontSize: "25px"}}>DevHome is an interface between the Google Assistant and your smart home devices. Are you interested into using the Google Assistant to control your Internet-connected devices?</p>
                        <Button.Group size="massive">
                            <Button labelPosition="left" icon="x" content="No" onClick={() => {
                                this.setState({flowProgress: -1});
                            }} />
                            <Button.Or />
                            <Button positive labelPosition="right" icon="check" content="Yes" onClick={() => {
                                this.setState({flowProgress: 3});
                            }} />
                        </Button.Group>
                    </div>
                );
            case 3:
                return (
                    <div className="fullPagePadded" style={{paddingLeft: "50px", paddingRight: "50px"}}>
                        <Header as="h1" style={{fontSize: "50px"}}>Question 2/3</Header>
                        <p style={{fontSize: "25px"}}>DevHome is made for hobbyists who make their own Internet Of Things devices. In our alpha testing phase, we are looking for platforms to closely integrate with. Which platforms do you use for iOT development?</p>
                        <Header as="h2" style={{fontSize: "30px"}}>Select Platforms</Header>
                        <Dropdown
                            placeholder="Select your platforms..."
                            multiple
                            search
                            selection
                            options={platformOptions}
                            value={this.state.platformDropdown}
                            onChange={(e, props) => {
                                this.setState({platformDropdown: props.value});
                            }}
                        />
                        <Header as="h2" style={{fontSize: "30px"}}>Other</Header>
                        <Input placeholder="Any other platforms?" value={this.state.platformOther} onChange={(e, props) => {
                            this.setState({platformOther: props.value});
                        }} />
                        <Divider hidden />
                        <Button disabled={this.state.platformDropdown.length == 0 && this.state.platformOther == ""} size="massive" positive labelPosition="right" icon="right arrow" content="Next" onClick={() => {
                            this.setState({flowProgress: 4});
                        }} />
                    </div>
                );
            case 4:
                return (
                    <div className="fullPagePadded" style={{paddingLeft: "50px", paddingRight: "50px"}}>
                        <Header as="h1" style={{fontSize: "50px"}}>Question 3/3</Header>
                        <p style={{fontSize: "25px"}}>To register for the alpha program, you must have a Google account. Please provide the email linked to your Google account.</p>
                        <Header as="h2" style={{fontSize: "30px"}}>Email</Header>
                        <Input error={!validateEmail(this.state.email)} type="email" placeholder="Email" value={this.state.email} onChange={(e, props) => {
                            this.setState({email: props.value});
                        }} />
                        <Divider hidden />
                        <a target="_blank" href={"mailto:eric@yoonicode.com?subject=DevHome%20Alpha%20Testing&body=I%20would%20like%20to%20alpha%20test%20DevHome.%0A%0Aplatforms%3A%20" + this.state.platformDropdown.join(", ") + ", " + this.state.platformOther + "%0Aemail%3A%20" + this.state.email}>
                            <Button disabled={!validateEmail(this.state.email)} size="massive" positive labelPosition="right" icon="right arrow" content="Sign Up" />
                        </a>
                    </div>
                );
            case -1:
                    return (
                        <div className="fullPagePadded" style={{paddingLeft: "50px", paddingRight: "50px"}}>
                            <Header as="h1" style={{fontSize: "50px"}}>Other Platforms Coming Soon</Header>
                            <p style={{fontSize: "25px"}}>Unfortunately, DevHome currently only supports the Google Assistant. Support for other voice assistants, such as Alexa or HomeKit, is in development.</p>
                            <Divider hidden />
                            
                            <Link to="home">
                                <Button size="massive" primary labelPosition="right" icon="right arrow" content="Back Home" />
                            </Link>
                        </div>
                    );
        }
        
    }
}